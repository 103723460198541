/*General Styles*/

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Noto Sans HK", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans HK", Helvetica, Arial, sans-serif;
  background: #fff;
  scroll-behavior: smooth;
  animation: entranceAnime 2s linear 1;
}

h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

h1 {
  color: #2c3d89;
  font-size: 32px;
}

h2 {
  color: #2c3d89;
  font-size: 28px;
  margin-bottom: 20px;
}

h3,
h4,
h6 {
  color: #0d8b44;
}

p {
  color: #040404;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.75px;
}

button,
a,
.client-images,
#menu_icon {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Header Styling */

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px #2c3d89;
  position: relative;
  padding: 15px 20px;
  transition: all 400ms ease-in-out;
}

/* Logo Styling */

.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-height: 130px;
  margin-right: 10px;
}

/* Navigation Styling */

#menu_icon {
  position: relative;
  font-size: 50px;
  background: #2c3d89;
  border-radius: 50%;
  color: #0d8b44;
  padding: 5px;
  cursor: pointer;
  display: none;
  transition: all 400ms ease-in-out;
}

#menu_icon.bx-x {
  color: #ea1d26;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
}

nav ul li {
  margin-right: 10px;
  position: relative;
}

#home_icon {
  font-size: 35px;
}

.right-side {
  font-size: 22px;
  position: relative;
  top: 7px;
}

nav ul li a {
  text-decoration: none;
  color: #2c3d89;
  font-weight: 700;
  transition: all 500ms ease-in-out;
  padding: 20px;
  padding-bottom: 40px;
}

nav ul li a.active {
  color: #0d8b44;
}

nav ul li:hover a {
  color: #0d8b44;
}

/* Submenu Styling */

nav ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  background-size: contain;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px #2c3d89;
  margin-top: 30px;
}

nav ul li:hover > ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

nav ul li:hover > ul.left {
  left: auto;
  right: 0;
}

nav ul ul li {
  width: calc(
    33.333% - 20px
  ); /* Adjust width based on the number of columns and gap */
  margin-bottom: 30px;
}

nav ul ul a {
  padding: 15px;
  white-space: nowrap;
  transition: all 500ms ease-in-out;
}

nav ul ul a:hover {
  color: #2c3d89;
  text-decoration: underline;
}

nav ul ul a.active {
  color: #2c3d89;
}

/*Language Toggle Styling*/

.language-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.language-toggle h6 {
  color: #2c3d89;
  text-align: center;
}

.language-toggle button {
  background: #2c3d89;
  padding: 7px;
  cursor: pointer;
  box-shadow: 0 0 5px #2c3d89;
  border-radius: 5px;
  margin-top: -15px;
  border: none;
  transition: all 500ms ease-in-out;
}

.language-toggle button:hover {
  background: #0d8b44;
  box-shadow: 0 0 5px #0d8b44;
}

/*Maintenance Mode*/

.maintenance-mode {
  background: url("/public/assets/officials_background.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

.maintenance-mode h2 {
  font-size: 34px;
}

/* Introduction Section Styling */

.introduction-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.left-section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 50px;
  margin-top: 50px;
}

.left-section h1 {
  margin-bottom: 20px;
}

.intro-paragraph p {
  margin-bottom: 20px;
}

.intro-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 40px;
}

#contact_btn {
  background-color: #2c3d89;
  color: #0d8b44;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
  margin-right: 30px;
}

#contact_btn:hover {
  background-color: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 5px #0d8b44;
}

#more_about {
  background-color: #0d8b44;
  color: #2c3d89;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

#more_about:hover {
  background-color: #2c3d89;
  color: #0d8b44;
  box-shadow: 0 0 10px #2c3d89;
}

.right-section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-right: 50px;
  margin-top: 50px;
}

.img-container {
  text-align: center;
  max-width: 100%;
  margin: auto;
}

.img-container img {
  width: 450px;
  height: 450px;
  border-radius: 10px;
}

/* About Brief Section Styling */

.about-brief {
  margin-top: 100px;
}

.about-brief-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
}

.about-brief-content h2,
.about-brief-content p {
  text-align: center;
}

.about-brief-content p {
  margin-bottom: 40px;
}

.about-brief-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-brief-btn a {
  background-color: #2c3d89;
  color: #0d8b44;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

.about-brief-btn a:hover {
  background-color: #ea1d26;
  color: #2c3d89;
  box-shadow: 0 0 5px #ea1d26;
}

/*Achievements Overview Section Styling Begins*/

.achievements-overview {
  margin-bottom: 100px;
}

.achievements-overview .achievements-intro {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  margin: 0 50px 30px 50px;
}

.achievements-overview .achievements-intro h2 {
  margin-top: 70px;
}

.achievements-overview .achievements-intro h2 {
  text-align: center;
}

.achievements-overview .achievements-grid-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
}

.achievements-overview .achievements-grid-area .achievements-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 3px #2c3d89;
  border-radius: 10px;
  gap: 20px;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-img
  img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description {
  width: 300px;
  height: 200px;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description
  .achievement-desc-text
  p {
  font-size: 12px;
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.3;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description
  .achievement-explore-link
  a {
  color: #2c3d89;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description
  .achievement-explore-link
  a:hover {
  color: #0d8b44;
  transition: all 400ms ease-in-out;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description
  .achievement-explore-link
  a
  i {
  position: relative;
  top: 7px;
  left: 0;
  transition: all 400ms ease-in-out;
}

.achievements-overview
  .achievements-grid-area
  .achievements-grid
  .achievement-description
  .achievement-explore-link
  a:hover
  i {
  left: 3px;
}

/*Achievements Overview Section Styling Ends*/

/*Membership Intro Section Styling Begins*/

.membership-container {
  background: #0d8b44;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.membership-left {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 50px;
  margin-top: 10px;
}

.membership-right {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-right: 50px;
  margin-top: 50px;
}

.membership-paragraph p {
  margin-bottom: 20px;
}

.membership-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 40px;
}

#member_info {
  background-color: #2c3d89;
  color: #0d8b44;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
  margin-right: 30px;
}

#member_info:hover {
  background-color: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 5px #2c3d89;
}

#member_apply {
  background-color: #ea1d26;
  color: #2c3d89;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

#member_apply:hover {
  background-color: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 5px #2c3d89;
}

/*Membership Intro Section Styling Ends*/

/*Partner Recognition Section Styling*/

.partner-recognition-section {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  gap: 20px;
}

.partner-recognition-intro {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.partner-recognition-intro h2 {
  margin-bottom: 20px;
}

.partner-recognition-intro p {
  margin-bottom: 20px;
}

.partner-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.partner-logos div {
  display: flex;
  justify-content: center;
}

.partner-logos img {
  width: 170px;
  height: 170px;
}

.francophonie-link {
  margin-top: 20px;
  margin-bottom: 40px;
}

.francophonie-link a {
  font-size: 20px;
}

.francophonie-link a i {
  font-size: 40px;
  position: relative;
  left: 2px;
  transition: all 400ms ease-in-out;
}

.francophonie-link a:hover i {
  left: 7px;
}

/*Events Overview Section Styling*/

.events-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 70px;
}

.events-overview .main-event {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.events-overview .main-event .main-event-img {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.events-overview .main-event .main-event-img img {
  width: 600px;
  height: 400px;
}

.events-overview .main-event .main-event-details {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.events-overview .main-event .main-event-details h5 {
  color: #ea1d26;
}

.events-overview .main-event .main-event-details h3 {
  margin-bottom: 20px;
}

.events-overview .main-event .main-event-details .main-event-paragraph {
  margin-bottom: 20px;
}

.events-overview .main-event .main-event-details .main-event-link {
  display: flex;
  margin-top: 40px;
}

#events_info {
  color: #0d8b44;
  text-decoration: none;
  font-weight: 800;
  transition: all 400ms ease-in-out;
}

#events_info:hover {
  color: #2c3d89;
}

#events_info i {
  position: relative;
  top: 7px;
}

.events-overview .other-events {
  margin-top: 70px;
  margin-bottom: 100px;
}

.other-events .other-events-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 65px;
}

.other-events .other-events-flex .other-event-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
}

.other-events .other-events-flex .other-event-box .other-event-img img {
  width: 350px;
  height: 200px;
  border-radius: 10px;
}

.other-events .other-events-flex .other-event-box .other-event-description {
  width: 300px;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-desc-texts
  h5 {
  margin-top: 0;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-desc-texts
  h3 {
  margin-bottom: 10px;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-desc-texts
  p {
  font-size: 12px;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.3;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-explore-link
  a {
  color: #2c3d89;
  text-decoration: none;
  font-weight: 700;
  transition: all 400ms ease-in-out;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-explore-link
  a:hover {
  color: #0d8b44;
  transition: all 400ms ease-in-out;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-explore-link
  a
  i {
  position: relative;
  top: 7px;
  left: 0;
  transition: all 400ms ease-in-out;
}

.other-events
  .other-events-flex
  .other-event-box
  .other-event-description
  .event-explore-link
  a:hover
  i {
  left: 3px;
}

/*Publications Section Styling*/

.publications-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.publications-container .publications-intro h2 {
  text-align: center;
}

.publications-container .publications-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.publications-container .publications-grid .publication-box {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 20px;
  padding: 10px;
  border: 1px solid #2c3d89;
  border-bottom: 5px solid #0d8b44;
  border-radius: 10px;
}

.publications-container .publications-grid .publication-box .publication-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.publications-container
  .publications-grid
  .publication-box
  .publication-img
  img {
  width: 200px;
  height: 100px;
  border-radius: 10px;
}

.publications-container
  .publications-grid
  .publication-box
  .publication-text
  a {
  color: #0d8b44;
  text-decoration: none;
  font-weight: 700;
}

.publications-container
  .publications-grid
  .publication-box
  .publication-text
  a
  i {
  position: relative;
  top: 7px;
  left: 0;
  transition: all 400ms ease-in-out;
}

.publications-container
  .publications-grid
  .publication-box
  .publication-text
  a:hover
  i {
  left: 3px;
}

.publications-container
  .publications-grid
  .publication-box
  .publication-text
  p {
  line-height: 1.2;
  font-size: 12px;
}

/*Client Testimonials Section Styling*/

.client-testimonials-section {
  margin-top: 100px;
}

.client-testimonials-section .testi-wrapper {
  background: url("/public/assets/officials_background.png") no-repeat center;
  background-size: cover;
  margin: 40px;
  padding: 40px;
  border-radius: 10px;
}

.client-testimonials-intro {
  text-align: center;
  margin: 0 50px 50px 50px;
}

.client-testimonials-intro p {
  text-align: center;
}

.client-testimonials {
  margin: 0 50px;
}

.client-images {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.client-images img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 20px;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}

.client-images img.active {
  width: 170px;
  height: 170px;
  border: 2px solid #040404;
  opacity: 1;
  margin: 10px;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-content h3 {
  font-size: 25px;
  color: #040404;
}

.testimonial-content h4 {
  color: #2c3d89;
}

.testimonial-content p {
  margin: auto;
  text-align: center;
}

.mobile-testimonials {
  display: none;
}

/*Home Page Styling Ends*/

/*Schools By Region Styling*/

.schools-region-intro-bg {
  background: url("/public/assets/schools_region_bg.png") no-repeat center;
  background-size: cover;
  padding-bottom: 600px;
}

.schools-region-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.schools-region-intro {
  margin-top: 50px;
}

.schools-region-intro h2 {
  text-align: center;
}

.schools-region-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.schools-region-buttons button {
  background: #040404;
  color: #fff;
  border: none;
  outline: none;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 0 5px #040404;
  transition: all 400ms ease-in-out;
}

.schools-region-buttons button:hover,
.schools-region-buttons button.active {
  background: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 10px #040404;
  cursor: pointer;
}

.region-schools-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.region-schools-table h3 {
  text-align: center;
  margin-bottom: 20px;
}

.region-schools-table table {
  text-align: center;
  border-color: #0d8b44;
  border-radius: 10px;
}

.region-schools-table table thead {
  background: #0d8b44;
  color: #2c3d89;
}

.region-schools-table table tbody {
  color: #0d8b44;
}

/*About Page Styling Begins*/

/**About Introduction Section Styling**/

.about-us-intro {
  background: url("/public/assets/about_intro.webp") no-repeat center;
  background-size: cover;
  padding-top: 300px;
  padding-bottom: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-intro h1 {
  color: #fff;
  font-size: 38px;
}

/**About Us Detailed**/

.about-us-detailed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.about-us-detailed .about-detailed-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-detailed .about-detailed-content h2 {
  text-align: center;
}

.about-us-detailed .about-detailed-content p {
  text-align: center;
}

.about-us-detailed .about-detailed-content button {
  background: #2c3d89;
  color: #0d8b44;
  padding: 10px 20px;
  margin-top: 20px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.about-us-detailed .about-detailed-content button:hover {
  background: #0d8b44;
  color: #2c3d89;
}

.about-us-detailed .about-detailed-content button i {
  position: relative;
  top: 7px;
  font-size: 24px;
}

/**About Mission Vision Values**/

.mission-vision-values {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 40px;
  margin: 50px;
}

.mission-vision-values .mission-vision-value-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 3px solid #0d8b44;
  border-bottom: 3px solid #0d8b44;
}

.mission-vision-values .mission-vision-value-box .heading-icon img {
  width: 70px;
  height: 70px;
  position: relative;
  left: 50px;
}

/***About Objectives*/

.objectives-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.objectives-intro {
  display: flex;
  gap: 10px;
}

.objectives-intro i {
  color: #2c3d89;
  position: relative;
  bottom: 20px;
  font-size: 65px;
}

.objectives-list p {
  margin-bottom: 10px;
}

.objectives-list p i {
  color: #2c3d89;
}

/**About Structure**/

.about-structure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-left: 20px;
  margin-right: 20px;
}

.about-structure img {
  max-width: 100%;
}

.about-structure p {
  text-align: center;
  margin-bottom: 30px;
}


/***About Regional Governing Bodies*/

.regional-governers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.regional-governers-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 200px;
}

.region-card {
  background-image: linear-gradient(#0d8b44, #2c3d89);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px #040404;
}

.alternate-gradient {
  background-image: linear-gradient(#2c3d89, #0d8b44);
}

.region-card h3 {
  color: #fff;
  margin-bottom: 10px;
}

.region-card p {
  font-weight: 500;
}

.region-card p span,
.region-card p.fw-700 {
  font-weight: 700;
}

.region-card p.fw-700 span.fw-500 {
  font-weight: 500;
}

.region-card h4 {
  color: #ea1d26;
}

/**About Teacher Numbers**/

.teacher-numbers {
  background: url("/public/assets/teacher_numbers_bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.teacher-numbers .teacher-numbers-intro {
  margin-left: 20px;
  margin-right: 20px;
}

.teacher-numbers .teacher-numbers-intro h2 {
  color: #0d8b44;
  text-align: center;
}

.teacher-numbers .teacher-numbers-intro p {
  text-align: center;
}

.teacher-numbers .teacher-numbers-table table {
  text-align: center;
  border-color: #0d8b44;
  border-radius: 10px;
}

.teacher-numbers .teacher-numbers-table table thead,
.teacher-numbers .teacher-numbers-table table tr:last-child {
  background: #0d8b44;
  color: #2c3d89;
}

.teacher-numbers .teacher-numbers-table table tbody {
  color: #0d8b44;
}

/**About Regions**/

.about-regions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}

/**About Lead Gen Mini Section Styling**/

.lead-gen-mini-main {
  background: #0d8b44;
  display: flex;
  flex-direction: column;
  gap: 70px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.lead-gen-mini-main .lead-gen-mini-heading {
  text-align: center;
}

.lead-gen-mini-main .lead-gen-mini-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.lead-gen-mini-main .lead-gen-mini-btn a {
  background: #2c3d89;
  color: #0d8b44;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  padding: 20px 40px;
  transition: all 400ms ease-in-out;
}

.lead-gen-mini-main .lead-gen-mini-btn a:hover {
  background: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 10px #2c3d89;
}

.lead-gen-mini-main .lead-gen-mini-btn a i {
  font-size: 25px;
  position: relative;
  top: 4px;
  left: 4px;
  transition: all 400ms ease-in-out;
}

.lead-gen-mini-main .lead-gen-mini-btn a:hover i {
  left: 7px;
}

/**About Outro Section Styling**/

.about-outro {
  background: #0d8b44;
  display: flex;
  flex-direction: column;
  gap: 70px;
  justify-content: center;
  align-items: center;
  margin: 150px 50px;
  padding-top: 200px;
  padding-bottom: 200px;
  border-radius: 10px;
}

.about-outro .about-outro-heading h2 {
  text-align: center;
}

.about-outro .about-outro-btn a {
  background: #2c3d89;
  color: #0d8b44;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  padding: 20px 40px;
  transition: all 400ms ease-in-out;
}

.about-outro .about-outro-btn a:hover {
  background: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 10px #2c3d89;
}

.about-outro .about-outro-btn a i {
  font-size: 25px;
  position: relative;
  top: 4px;
  left: 4px;
  transition: all 400ms ease-in-out;
}

.about-outro .about-outro-btn a:hover i {
  left: 7px;
}

/*About Page Styling Ends*/

/*Events Page (Reused For News) Styling Begins*/

.events-intro {
  background: #0d8b44 url("/public/assets/events_intro.webp") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

.news-intro {
  background-image: url("/public/assets/news_intro.webp");
}

.events-intro h1 {
  color: #2c3d89;
  text-align: center;
  font-size: 40px;
}

.news-intro h1 {
  color: #fff;
}

.events-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 50px 20px 50px 20px;
}

.events-main .event-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.events-main .event-buttons button {
  background: #2c3d89;
  color: #0d8b44;
  padding: 20px 40px;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.news-main .news-buttons button {
  background: #0d8b44;
  color: #2c3d89;
}

.events-main .event-buttons button:hover,
.events-main .event-buttons button.active {
  background: #0d8b44;
  color: #2c3d89;
  box-shadow: 0 0 5px #0d8b44;
}

.news-main .news-buttons button:hover,
.news-main .news-buttons button.active {
  background: #2c3d89;
  color: #0d8b44;
  box-shadow: 0 0 5px #2c3d89;
}

.events-main .event-content .upcoming-events-list,
.events-main .event-content .past-events-list,
.news-main .news-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.events-main .event-content .upcoming-events-list {
  margin-bottom: 30px;
}

.events-main .event-content .event-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 400ms ease-in-out;
  width: 350px;
  box-shadow: 0 0 2px #2c3d89;
  border-radius: 10px;
}

.news-main .news-content .news-card {
  box-shadow: 0 0 2px #0d8b44;
}

.events-main .event-content .event-card:hover {
  background: #0d8b44;
  cursor: pointer;
}

.news-main .news-content .news-card:hover {
  background: #2c3d89;
}

.events-main .event-content .event-card .event-image img {
  width: 350px;
  height: 300px;
  border-radius: 10px;
}

.events-main .event-content .event-card .event-headings {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
}

.events-main .event-content .event-card .event-headings h5 {
  color: #2c3d89;
}

.events-main .event-content .event-card .event-headings h5.red-heading {
  color: #ea1d26;
}

.news-main .news-content .news-card .news-headings h5 {
  color: #0d8b44;
}

.events-main .event-content .event-card:hover .event-headings h3 {
  color: #2c3d89;
}

.news-main .news-content .news-card .news-headings h3 {
  color: #2c3d89;
}

.news-main .news-content .news-card:hover .news-headings h3 {
  color: #0d8b44;
}

.events-main .event-content .event-card .event-headings a {
  color: #2c3d89;
  text-decoration: none;
  font-weight: 700;
  transition: all 400ms ease-in-out;
}

.news-main .news-content .news-card .news-headings a {
  color: #0d8b44;
}

.events-main .event-content .event-card .event-headings a:hover {
  color: #ea1d26;
}

.events-main .event-content .event-card .event-headings a i {
  position: relative;
  top: 2px;
  left: 3px;
  font-size: 20px;
  transition: all 400ms ease-in-out;
}

.events-main .event-content .event-card .event-headings a:hover i {
  left: 7px;
}

.events-main .event-content .event-card .event-headings #label {
  background: #0d8b44;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  transition: all 400ms ease-in-out;
}

.news-main .news-content .news-card .news-headings #label {
  background: #2c3d89;
}

.events-main .event-content .event-card:hover .event-headings #label {
  background: #2c3d89;
  color: #0d8b44;
}

.news-main .news-content .news-card:hover .news-headings #label {
  background: #0d8b44;
  color: #2c3d89;
}

/**Event Details**/

.event-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px;
}

.event-details .jipf-content p {
  margin-bottom: 30px;
}

.event-details .jipf-content a {
  background: #2c3d89;
  color: #0d8b44;
  text-decoration: none;
  font-weight: 700;
  padding: 20px 30px;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

.event-details .jipf-content a:hover {
  background: #0d8b44;
  color: #2c3d89;
}

.event-details .jipf-content a i {
  position: relative;
  top: 5px;
  left: 3px;
  font-size: 30px;
  transition: all 400ms ease-in-out;
}

.event-details .jipf-content a:hover i {
  left: 7px;
}

/*Events Page Styling Ends*/

/*Resources Page Styling Begins*/

.resources-intro {
  background:#0d8b44 url("/public/assets/resources_intro.webp") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 170px;
  margin-bottom: 40px;
}

.resources-intro h1 {
  color: white;
}

.resources-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
}

.resources-list .resources-list-intro p {
  text-align: center;
}

.resources-list .resources-list-intro h2 {
  text-align: center;
  margin-top: 20px;
}

.resources-list .resources-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  list-style: none;
}

.resources-list .resources-grid .resource-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resources-list .resources-grid .resource-item a {
  color: #0d8b44;
  font-weight: 700;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.resources-list .resources-grid .resource-item a:hover {
  color: #2c3d89;
}

.resources-list .resources-grid .resource-item a img {
  width: 100px;
  height: 100px;
}

/*Resources Page Styling Ends*/

/*Membership Page Styling Begins*/

.membership-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.membership-main .membership-intro {
  background: #0d8b44 url("/public/assets/membership_intro.webp") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 300px;
  padding-bottom: 300px;
}

.membership-main .membership-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.membership-main .membership-details p {
  text-align: center;
}

.membership-main .membership-details .member-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.membership-main .membership-details .member-info .info-card {
  background: #2c3d89;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #2c3d89;
  transition: all 400ms ease-in-out;
  width: 500px;
}

#join_how {
  padding-bottom: 350px;
}

.membership-main .membership-details .member-info .info-card:hover {
  box-shadow: none;
}

.membership-main .membership-details .member-info .info-card ul {
  list-style-type: none;
}

.membership-main .membership-details .member-info .info-card ul li {
  margin-bottom: 10px;
  transition: all 400ms ease-in-out;
}

.membership-main .membership-details .member-info .info-card ul li i {
  color: #0d8b44;
  position: relative;
  top: 1px;
  transition: all 400ms ease-in-out;
}

.membership-main .membership-details .member-info .info-card ul li:hover i {
  color: #ea1d26;
  top: 0;
}

.payment-guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.payment-guide .payment-guide-heading h2 {
  text-align: center;
}

.payment-guide .payment-content {
  background: #040404;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 400px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.payment-guide .payment-content h5 {
  color: #2c3d89;
}

.payment-guide .payment-content h5 span {
  color: #ea1d26;
  font-size: 18px;
}

/*Membership Page Styling Ends*/

/*Contact Page Styling Begins*/

.contact-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 50px;
}

.contact-container .contact-form form {
  background-image: linear-gradient(#fff, #0d8b44, #0d8b44, #fff);
  padding: 40px 200px;
  text-align: center;
}

.contact-container .contact-form form label {
  color: #2c3d89;
  font-weight: 700;
}

.contact-container .contact-form form input,
.contact-container .contact-form form textarea {
  color: #0d8b44 !important;
  padding: 10px 40px;
  border: 3px solid #2c3d89 !important;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

.contact-container .contact-form form input:focus,
.contact-container .contact-form form textarea:focus {
  color: #0d8b44 !important;
  outline: none;
  border: 3px solid #fff !important;
}

.contact-container .contact-form form textarea {
  resize: none;
}

.contact-container .contact-form form small {
  color: #ff0000;
  font-size: 10px;
}

.contact-container .contact-form .success-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container .contact-form .success-message small {
  color: #0071bc;
}

.contact-container .contact-form form button {
  background: #2c3d89;
  color: #0d8b44 !important;
  padding: 20px 40px;
  font-weight: 700;
  border-radius: 10px;
  border: none !important;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.contact-container .contact-form form button:hover {
  background: #0d8b44;
  color: #2c3d89 !important;
}

.contact-container .contact-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container .contact-img img {
  width: 500px;
  height: 500px;
}

.contact-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-socials a i {
  background: #0d8b44;
  color: #2c3d89;
  font-size: 70px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 0 5px #2c3d89;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

.contact-socials a i:hover {
  background: #2c3d89;
  color: #0d8b44;
  box-shadow: 0 0 10px #0d8b44;
}

/*Contact Page Styling Ends*/

/*Gallery Page Styling Begins*/

.site-gallery {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
}

.site-gallery .image-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.site-gallery .image-box img {
  width: 400px;
  height: 300px;
  border-radius: 10px;
}

.site-gallery .image-box p {
  color: #0d8b44;
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

/*Gallery Page Styling Ends*/

/*Francophonie Au Kenya Styling Begins*/

.francophonie-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.francophonie-intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.francophonie-intro .intro-left img {
  max-width: 100%;
  height: auto;
}

.francophonie-intro .intro-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-right: 10px;
}

.francophonie-intro .intro-right p {
  text-align: justify;
}

.francophonie-intro .intro-right a {
  background: #25d366;
  color: #2c3d89;
  font-weight: 700;
  padding: 20px 40px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.francophonie-intro .intro-right a:hover {
  background: #2c3d89;
  color: #25d366;
}

.francophonie-section .francophonie-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px;
}

.francophonie-section .francophonie-content .content-block {
  width: 100%;
  margin-top: 20px;
}

.francophonie-section .francophonie-content .content-block h2 {
  text-align: center;
}

.francophonie-section .francophonie-content .content-block .content-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.francophonie-section
  .francophonie-content
  .content-block
  .content-items
  .content-item {
  background: #2c3d89;
  color: #0d8b44;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  flex: 1 1 200px;
  text-align: center;
}

.francophonie-section
  .francophonie-content
  .content-block
  .content-items
  #togreen {
  background: #0d8b44;
  color: #2c3d89;
}

/*Francophonie Au Kenya Styling Ends*/

/*Privacy Policy Page Styling Begins*/

.privacy-notice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.privacy-notice p {
  text-align: center;
  font-size: 18px;
  line-height: 1.1;
}

.privacy-notice h2 {
  font-size: 24px;
  text-align: center;
}

.privacy-notice h3 {
  font-size: 20px;
  color: #2c3d89;
  text-align: center;
}

.privacy-notice ul {
  color: #0071bc;
  line-height: 1.7;
  margin-left: 20px;
}

.privacy-notice ul li {
  margin-bottom: 10px;
}

.privacy-notice a {
  color: #0071bc;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.privacy-notice a:hover {
  color: #2c3d89;
}

/*Privacy Policy Page Styling Ends*/

/*Footer Section Styling*/

footer {
  box-shadow: 0 0 5px #0d8b44 inset;
}

.footer {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 50px;
}

.footer-left {
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 50px;
}

.footer-left .footer-logo {
  display: flex;
  justify-content: left;
}

.footer-left .footer-logo img {
  width: 150px;
  height: 150px;
  margin-top: 30px;
}

.footer-left .footer-text p {
  color: #2c3d89;
  text-align: justify;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.footer-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  text-align: center;
}

.footer-center h4 {
  font-size: 24px;
}

.footer-center ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
}

.footer-center ul li a {
  color: #2c3d89;
  text-decoration: none;
  font-size: 12px;
  transition: all 400ms ease-in-out;
}

.footer-center ul li a:hover {
  color: #0d8b44;
}

.footer-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 50px 50px 0 55px;
}

.footer-left .footer-right,
.footer-center {
  margin-bottom: 50px;
}

.footer-right .connect h4 {
  font-size: 24px;
  text-align: left;
}

.footer-right .connect ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
}

.footer-right .connect ul li a {
  color: #2c3d89;
  text-decoration: none;
  font-size: 12px;
  transition: all 400ms ease-in-out;
}

.footer-right .connect ul li a:hover {
  color: #0d8b44;
}

.footer-right .connect ul li a i {
  position: relative;
  top: 4px;
  font-size: 30px;
}

.footer-socials {
  display: flex;
  margin-top: 25px;
}

.footer-socials a i {
  background: #2c3d89;
  color: #0d8b44;
  font-size: 35px;
  border: 1px solid #2c3d89;
  border-radius: 50%;
  padding: 4px;
  margin-right: 10px;
  box-shadow: 0 0 5px #0d8b44;
  transition: all 400ms ease-in-out;
}

.footer-socials a i:hover {
  box-shadow: none;
}

.footer-lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-lower .copyright p {
  font-size: 10px;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 1000;
}

.scroll-to-top button {
  background: #0d8b44;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

.scroll-to-top button:hover {
  background: #2c3d89;
  cursor: pointer;
}

.scroll-to-top button i {
  color: #2c3d89;
  font-size: 24px;
  transition: all 400ms ease-in-out;
}

.scroll-to-top button:hover i {
  color: #ea1d26;
}

/*Responsive Design BREAKPOINTS*/

/*MAX w 1200*/

@media (max-width: 1200px) {
  /*General*/

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  /*Header*/

  .header {
    column-gap: 300px;
  }

  #menu_icon {
    display: block;
  }

  #main_nav {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    padding: 0;
    transition: all 400ms ease-in-out;
    transition-delay: 25ms;
  }

  #main_nav.active {
    left: 0;
    transition-delay: 0s;
  }

  #main_nav ul {
    background: #2c3d89;
    display: block;
    text-align: center;
    max-width: 100%;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 5px #0d8b44;
    align-items: center;
    cursor: pointer;
  }

  #main_nav ul li {
    margin: 0;
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
  }

  #main_nav ul li a {
    color: #0d8b44;
    transition: all 500ms ease-in-out;
  }

  #main_nav ul li a:hover,
  #main_nav ul li a.active {
    color: #ea1d26;
  }

  #main_nav ul li span {
    display: none;
  }

  #main_nav ul ul {
    display: none;
    position: absolute;
    top: -55px;
    left: 60%;
    z-index: 2;
    padding: 10px;
    border-radius: 5px;
  }

  #main_nav ul li:hover > ul {
    display: block;
  }

  #main_nav ul li:hover > ul li {
    font-size: 16px;
  }

  .language-toggle {
    margin-right: 10px;
  }

  /*Introduction*/

  #contact_btn,
  #more_about {
    padding: 15px 30px;
    white-space: nowrap;
  }

  /*Events Overview*/

  .events-overview .main-event {
    gap: 10px;
  }

  .events-overview .main-event .main-event-img .img-container img {
    width: 500px;
    height: 250px;
  }

  .events-overview .main-event .main-event-details h3 {
    margin-bottom: 10px;
  }

  .events-overview .main-event .main-event-details .main-event-paragraph {
    margin-bottom: 10px;
  }

  /*Membership Intro*/

  #member_info,
  #member_apply {
    padding: 15px 30px;
  }

  /*Service Overview*/

  .service {
    padding: 20px;
  }

  .service-icon span {
    font-size: 70px;
  }

  .service h3 {
    font-size: 18px;
  }

  .service p {
    font-size: 11px;
  }

  .service-btn button {
    padding: 15px 30px;
    font-weight: 700;
    border-width: 2px;
  }

  /*Partner Recognition*/

  .partner-logos img {
    width: 140px;
    height: 140px;
  }

  /*Client Testimonials*/

  .client-images img {
    width: 100px;
    height: 100px;
  }

  .client-images img.active {
    width: 130px;
    height: 130px;
  }

  .testimonial-content h3 {
    font-size: 16px;
  }

  .testimonial-content h4 {
    font-size: 12px;
  }

  .testimonial-content p {
    font-size: 14px;
    line-height: 1.2;
  }

  /*Footer*/

  .footer-left {
    margin-left: 20px;
  }

  .footer-left .footer-logo img {
    width: 130px;
    height: 130px;
  }

  .footer-left .footer-text p {
    font-size: 11px;
    line-height: 1.3;
  }

  .footer-center .center-content h4 {
    font-size: 20px;
  }

  .footer-center .center-content ul {
    row-gap: 7px;
  }

  .footer-center .center-content ul li a {
    font-size: 11px;
  }

  .footer-right {
    margin-left: 70px;
  }

  .footer-right .connect h4 {
    font-size: 20px;
  }

  .footer-right .connect ul li a {
    font-size: 11px;
  }

  .footer-socials {
    margin-top: 17px;
  }

  .footer-socials a i {
    font-size: 30px;
    padding: 3px;
    margin-right: 15px;
  }
}

/*MAX w 991*/

@media (max-width: 991px) {
  /*General*/
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  /*Header*/

  .header {
    padding: 10px;
  }

  .logo {
    margin-left: 20px;
  }

  .logo img {
    max-width: 100px;
    min-height: 100px;
  }

  #menu_icon {
    font-size: 40px;
  }

  .cta-button {
    margin-right: 20px;
  }

  .cta-button a {
    padding: 15px;
  }

  /*Introduction*/

  .introduction-container {
    gap: 15px;
  }

  .img-container img {
    width: 310px;
    height: 310px;
  }

  .intro-buttons {
    padding: 0;
  }

  #contact_btn,
  #more_about {
    padding: 10px 25px;
    border-width: 2px;
  }

  /*Achievements Overview*/

  .achievements-grid .achievement-description {
    gap: 10px;
  }

  .achievement-description .achievement-explore-link a {
    font-size: 12px;
  }

  /*Achievements Overview*/

  /*Membership Intro*/

  .membership-container {
    gap: 15px;
  }

  .membership-buttons {
    padding: 0;
  }

  #member_info,
  #member_apply {
    padding: 10px 25px;
    border-width: 2px;
  }

  /*Events Overview*/

  .events-overview {
    margin-left: 20px;
    margin-right: 20px;
  }

  .events-overview .main-event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .main-event .main-event-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-event .main-event-img .img-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .events-overview .main-event .main-event-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  .events-overview .main-event .main-event-details h5 {
    text-align: center;
  }

  .events-overview .main-event .main-event-details h2 {
    text-align: center;
  }

  .events-overview .main-event .main-event-details h3 {
    margin-bottom: 10px;
    text-align: center;
  }

  .events-overview .main-event .main-event-details .main-event-paragraph {
    margin-bottom: 0px;
  }

  .events-overview .main-event .main-event-details .main-event-paragraph p {
    line-height: 1.5;
  }

  .events-overview .main-event .main-event-details .main-event-link {
    margin-top: 20px;
  }

  /*Publications Section Styling*/

  .publications-container .publications-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  /*Service Overview*/

  .service {
    padding: 5px;
    border-width: 1.5px;
  }

  .service-icon span {
    font-size: 40px;
  }

  .service h3 {
    font-size: 14px;
  }

  .service p {
    font-size: 10px;
    line-height: 1.2;
  }

  .service-btn button {
    padding: 10px 25px;
    border: none;
  }

  /*Partner Recognition*/

  .partner-recognition-section {
    margin-left: 20px;
    margin-right: 20px;
  }

  .partner-recognition-intro p {
    text-align: center;
    margin-bottom: 20px;
  }

  .partner-logos img {
    width: 150px;
    height: 150px;
  }

  /*About Page Responsive Styling Begins Here*/

  /**About Introduction**/

  .about-us-intro {
    padding-top: 200px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-us-intro h1 {
    text-align: center;
  }

  .about-introduction {
    gap: 15px;
  }

  .about-intro-left {
    margin-left: 30px;
  }

  .about-intro-left a {
    padding: 15px 35px;
  }

  .about-intro-right {
    margin-right: 30px;
  }

  /**About Brief**/

  .about-brief-content {
    margin-right: 30px;
    margin-left: 30px;
  }

  /**About Mission Vission Values**/

  .mission-vision-values {
    gap: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .mission-vision-values .mission-vision-value-box .heading-icon img {
    left: 30px;
  }

  .mission-vision-values .values .box-text {
    gap: 20px;
  }


  /**About Regional Governing Bodies**/

  .regional-governers-cards {
    column-gap: 100px;
  }

  /**About Outro Section Styling**/

  .about-outro {
    gap: 50px;
    margin: 100px 20px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .about-outro .about-outro-btn a {
    font-weight: 600;
    padding: 20px;
  }

  /*About Page Responsive Styling Ends Here*/

  /*Contact Page Responsive Styling Begins Here*/

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .contact-container .contact-img {
    display: none;
  }

  /*Contact Page Responsive Styling Ends Here*/

  /*Footer*/

  .footer {
    gap: 10px;
  }

  .footer-left {
    margin-left: 10px;
  }

  .footer-left .footer-logo img {
    width: 110px;
    height: 110px;
  }

  .footer-left .footer-text p {
    font-size: 10px;
    line-height: 1.2;
  }

  .footer-center .center-content h4 {
    font-size: 18px;
    white-space: nowrap;
  }

  .footer-center .center-content ul {
    row-gap: 5px;
  }

  .footer-center .center-content ul li a {
    font-size: 10px;
  }

  .footer-right .connect h4 {
    font-size: 18px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .footer-right .connect ul li a {
    font-size: 10px;
  }

  .footer-socials a i {
    font-size: 28px;
    margin-right: 7px;
  }

  .footer-lower .copyright p {
    font-size: 8px;
  }
}

/*MAX w 814 && MIN w 769*/

@media only screen and (max-width: 814px) and (min-width: 769px) {
  .header {
    column-gap: 200px;
  }

  /*Home Introduction*/

  .introduction-container {
    gap: 5px;
  }

  .img-container img {
    width: 350px;
    height: 350px;
  }

  .intro-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  #contact_btn,
  #more_about {
    padding: 15px 20px;
    border-width: 2px;
  }

  #contact_btn {
    margin-right: 0;
  }
}

/*MAX w 990 && MIN w 521*/

@media only screen and (max-width: 990px) and (min-width: 521px) {
  .contact-client-testimonials p {
    color: #081b29;
  }

  .contact-client-testimonials h4 {
    color: #0071bc;
  }
}

/*MAX w 903*/

@media (max-width: 910px) {
  .payment-guide .payment-content {
    gap: 40px;
  }
}

/*MAX w 768*/

@media (max-width: 768px) {
  /*General*/

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  /*Header*/

  .header {
    column-gap: 200px;
    padding: 5px;
  }

  .logo {
    margin-left: 20px;
  }

  .logo img {
    max-width: 80px;
    min-height: 80px;
  }

  #menu_icon {
    font-size: 34px;
  }

  #main_nav ul {
    padding: 20px;
  }

  #main_nav ul li {
    margin-bottom: 25px;
    font-size: 14px;
  }

  #main_nav ul li span {
    font-size: 19px;
    top: 5px;
  }

  #main_nav ul ul {
    top: -40px;
  }

  #main_nav ul li:hover > ul li {
    font-size: 11px;
  }

  #main_nav ul li:hover > ul li .sub-side {
    font-size: 21px;
  }

  .cta-button {
    margin-right: 20px;
  }

  .cta-button a {
    padding: 10px;
    font-size: 14px;
  }

  /*Introduction*/

  .introduction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .introduction-container .right-section {
    margin: 0;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-container img {
    width: 400px;
    height: 400px;
  }

  .introduction-container .left-section {
    margin: 0;
  }

  .left-section {
    max-width: 100%;
    margin: auto;
  }

  .left-section h1 {
    text-align: center;
    margin-top: 50px;
  }

  .left-section .intro-paragraph p {
    margin: 10px 30px;
    text-align: justify;
  }

  .left-section .intro-buttons {
    justify-content: center;
    gap: 40px;
    padding: 0;
    font-size: 14px;
  }

  #contact_btn,
  #more_about {
    padding: 10px 20px;
  }

  /*Achievements Overview*/

  .achievements-intro {
    margin: 0 20px 30px 20px;
  }

  .achievements-grid .achievement-description {
    gap: 20px;
  }

  /*Membership Intro*/

  .membership-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .membership-container .membership-left {
    margin: 0;
  }

  .membership-container .membership-right {
    margin: 0;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .membership-container .membership-right h2 {
    text-align: center;
  }

  .membership-right .membership-paragraph p {
    margin: 10px 30px;
    text-align: justify;
  }

  .membership-buttons {
    justify-content: center;
    gap: 40px;
    padding: 0;
    font-size: 14px;
  }

  #member_info,
  #member_apply {
    padding: 10px 20px;
  }

  /*Events Overview*/

  .events-overview .main-event .main-event-img {
    margin: 0;
  }

  .events-overview .main-event .main-event-details {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .events-overview .main-event .main-event-details .main-event-paragraph p {
    margin: 10px 20px;
    text-align: justify;
  }

  .events-overview .main-event .main-event-details .main-event-link {
    justify-content: center;
    gap: 0;
    padding: 0;
  }

  .other-event-box .other-event-description {
    gap: 20px;
  }

  /*Service Overview*/

  .s-overview-main {
    margin-top: 70px;
  }

  .s-overview-intro {
    margin: 0 20px 50px 20px;
  }

  .services-overview {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .service {
    padding: 0;
    border-width: 1.2px;
  }

  .service-icon span {
    font-size: 35px;
    padding: 25px;
    margin-top: 20px;
  }

  .service h3 {
    font-size: 12px;
  }

  .service-btn {
    max-width: fit-content;
    margin: 20px auto;
  }

  .service-btn button {
    padding: 5px 15px;
    font-size: 11px;
    border: none;
    font-weight: 700;
  }

  .bottom-mover {
    margin-bottom: 70px;
  }

  /*Partner Recognition*/

  .partner-recognition-intro p {
    margin-bottom: 10px;
  }

  .brand-logos {
    gap: 30px;
  }

  .brand-logos img {
    width: 120px;
    height: 120px;
  }

  .francophonie-link {
    margin-top: 0px;
  }

  .francophonie-link a {
    font-size: 14px;
  }

  .francophonie-link a i {
    font-size: 30px;
  }

  /*Client Testimonials*/

  .client-testimonials-section {
    margin-top: 70px;
  }

  .client-testimonials-intro {
    text-align: center;
    margin: 0 20px 50px 20px;
  }

  .client-testimonials {
    margin: 0;
  }

  .client-images img {
    width: 50px;
    height: 50px;
  }

  .client-images img.active {
    width: 70px;
    height: 70px;
  }

  .testimonial-content h3 {
    font-size: 14px;
  }

  .testimonial-content p {
    font-size: 12px;
    line-height: 1.1;
  }

  /*About Page Responsive Styling Begins Here*/

  /**About Introduction**/

  .about-introduction {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .about-intro-left {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    align-items: center;
  }

  .about-intro-left h3,
  .about-intro-left h1,
  .about-intro-left p {
    text-align: center;
  }

  .about-intro-left a {
    font-size: 14px;
    padding: 20px;
  }

  .about-intro-right {
    margin-right: 20px;
    margin-left: 20px;
  }

  /**About Brief**/

  .about-brief {
    margin-top: 70px;
  }

  .about-brief-content {
    margin-right: 20px;
    margin-left: 20px;
  }

  /***About Mission Vission Values*/

  .mission-vision-values {
    gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .mission-vision-values .mission-vision-value-box .heading-icon img {
    left: 10px;
  }

  .mission-vision-values .values .box-text {
    gap: 5px;
  }

  /**About Regional Governing Bodies**/

  .regional-governers-cards {
    column-gap: 40px;
  }

  /**About Lead Gen Mini**/

  .lead-gen-mini-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lead-gen-mini-main .lead-gen-mini-btn a {
    font-weight: 600;
    padding: 15px 30px;
    font-size: 14px;
  }

  .lead-gen-mini-main .lead-gen-mini-btn a i {
    font-size: 20px;
    top: 3px;
  }

  /*About Page Responsive Styling Ends Here*/

  /*Contact Page Responsive Styling Begins Here*/

  .contact-container .contact-form form {
    padding: 40px 100px;
  }

  .contact-container .contact-img img {
    width: 350px;
    height: 350px;
  }

  .contact-socials {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .contact-socials a i {
    font-size: 40px;
  }

  /*Contact Page Responsive Styling Ends Here*/

  /*Gallery Page Responsive Styling Begins Here*/

  .site-gallery .image-box img {
    max-width: 100%;
    max-height: 100%;
  }

  /*Gallery Page Responsive Styling Ends Here*/

  /*Francophonie Au Kenya Styling Begins*/

  .francophonie-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .francophonie-intro .intro-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  /*Francophonie Au Kenya Styling Ends*/

  /*Footer*/

  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer-left {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .footer-left .footer-logo img {
    width: 150px;
    height: 150px;
  }

  .footer-left .footer-text p {
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
  }

  .footer-center {
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer-center .center-content h4 {
    font-size: 22px;
  }

  .footer-center .center-content ul li a {
    font-size: 12px;
  }

  .footer-right {
    margin-top: 0;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
  }

  .footer-right .connect h4 {
    font-size: 22px;
    text-align: center;
  }

  .footer-right .connect ul li a {
    font-size: 12px;
  }

  .footer-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .footer-socials a i {
    font-size: 34px;
    padding: 5px;
    margin: 0;
  }

  .footer-lower .copyright p {
    font-size: 10px;
  }
}

/*MAX w 600 && MIN w 521*/

@media only screen and (max-width: 600px) and (min-width: 521px) {
  .header {
    column-gap: 150px;
  }

  .inside input,
  .inside select {
    width: 400px;
  }

  .middle-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .middle-row select {
    width: 480px;
  }

  .bottom-row .inside #message {
    width: 400px;
  }
}

/*MAX w 520*/

@media (max-width: 520px) {
  /*General*/

  p {
    line-height: 1.5;
  }

  /*Header*/

  .header {
    column-gap: 130px;
    padding: 0;
  }

  .logo img {
    max-width: 70px;
    min-height: 70px;
  }

  #menu_icon {
    font-size: 32px;
  }

  #main_nav ul {
    padding: 10px;
  }

  #main_nav ul li {
    font-size: 16px;
  }

  #main_nav ul li:hover > ul li {
    font-size: 14px;
  }

  .cta-button {
    margin-right: 20px;
  }

  .cta-button a {
    padding: 15px 7px;
    font-size: 12px;
  }

  /*Introduction*/

  .intro-buttons {
    gap: 20px;
    font-size: 12px;
  }

  #contact_btn,
  #more_about {
    padding: 20px;
  }

  /*Achievements Overview*/

  .achievements-grid-area {
    margin: 0 20px;
  }

  /*Membership Intro*/

  .membership-buttons {
    gap: 20px;
    font-size: 12px;
  }

  #member_info,
  #member_apply {
    padding: 20px;
  }

  /*Events Overview*/

  .events-overview .main-event .main-event-details .main-event-link {
    font-size: 12px;
  }

  .other-events .other-events-flex {
    margin: 0 20px;
  }

  /*Publications*/

  .publications-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  /*Service Overview*/

  .s-overview-main {
    margin-top: 50px;
  }

  .services-overview {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }

  .service {
    border-width: 2px;
  }

  .service-icon span {
    font-size: 70px;
    padding: 20px;
  }

  .service h3 {
    font-size: 14px;
  }

  .service p {
    font-size: 12px;
  }

  .service-btn button {
    padding: 20px;
    font-size: 12px;
  }

  .bottom-mover {
    margin-bottom: 0;
  }

  .respo-below520 {
    margin-bottom: 50px;
  }

  /*Partner Recognition*/

  .partner-recognition-intro p {
    margin-bottom: 10px;
    text-align: justify;
  }

  .partner-logos img {
    width: 250px;
    height: 250px;
  }

  /*Client Testimonials*/

  .client-testimonials-section {
    margin-top: 50px;
  }

  .client-testimonials-section .testi-wrapper {
    margin: 10px;
    padding: 10px;
  }

  .client-testimonials-intro {
    text-align: center;
    margin: 0 10px 50px 10px;
  }

  .client-images img {
    width: 50px;
    height: 50px;
  }

  .client-images img.active {
    width: 70px;
    height: 70px;
  }

  .client-testimonials .testimonial-content h3 {
    font-size: 16px;
  }

  .testimonial-content p {
    margin-bottom: 20px;
  }

  /*About Page Responsive Styling Begins Here*/

  /**About Brief**/

  .about-brief {
    margin-top: 50px;
  }

  .about-brief-content {
    margin-left: 10px;
    margin-right: 10px;
  }

  .about-brief-content p {
    text-align: justify;
  }

  /**About Mission Vision Values**/

  .mission-vision-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .mission-vision-values .mission-vision-value-box {
    border-top-width: 2px;
    border-bottom: none;
  }

  .mission-vision-values .mission-vision-value-box .heading-icon img {
    width: 70px;
    height: 70px;
    position: relative;
    left: 10px;
  }

  /**About Regional Governing Bodies**/

  .regional-governers-container {
    margin-top: 40px;
  }

  .regional-governers-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
  }

  .region-card {
    width: 350px;
    height: 200px;
  }

  /*About Page Responsive Styling Ends Here*/

  /*Events Page Responsive Styling Begins Here*/

  .events-main .event-buttons {
    gap: 10px;
  }

  .events-main .event-buttons button {
    padding: 10px 30px;
    font-weight: 600;
  }

  /**Event Details**/

  .event-details {
    gap: 15px;
    margin: 50px 20px 50px 20px;
  }

  /*Events Page Responsive Styling Ends Here*/

  /*Membership Page Responsive Styling Begins Here*/

  .membership-main .membership-details .member-info .info-card {
    width: 400px;
  }

  /*Membership Page Responsive Styling Begins Here*/

  /*Privacy Policy Page Responsive Styling Begins*/

  .privacy-notice {
    margin: 30px;
  }

  .privacy-notice p {
    font-size: 16px;
  }

  .privacy-notice h2 {
    text-align: center;
  }

  .privacy-notice h3 {
    color: #2c3d89;
  }

  .privacy-notice ul {
    line-height: 1.5;
  }

  /*Privacy Policy Page Responsive Styling Ends*/

  /*Footer*/

  .footer {
    gap: 10px;
  }

  .footer-left {
    margin-left: 5px;
    margin-right: 5px;
  }

  .scroll-to-top button i {
    font-size: 20px;
  }
}

/*MAX w 462*/

@media (max-width: 462px) {
  /*Header*/

  .header {
    column-gap: 70px;
  }

  .logo img {
    max-width: 100px;
    max-height: 100px;
  }

  #menu_icon {
    font-size: 36px;
  }

  .cta-button {
    margin-right: 20px;
  }

  .cta-button a {
    padding: 15px;
    font-size: 13px;
  }

  /*Membership Intro*/

  .membership-right .membership-buttons {
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 20px;
  }

  /*Events Overview*/

  .events-overview {
    margin-left: 10px;
    margin-right: 10px;
  }

  /*Publications*/

  .publications-container .publications-grid .publication-box {
    display: flex;
    flex-direction: column;
    column-gap: 40px;
    padding: 10px;
  }

  /*Client Testimonials*/

  .client-testimonials-section {
    margin-top: 70px;
  }

  .client-testimonials {
    display: none;
  }

  .mobile-testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-testimonials img {
    width: 200px;
    height: 200px;
    border: 2px solid #081b29;
    border-radius: 50%;
  }

  .mobile-testimonials h3 {
    color: #081b29;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .mobile-testimonials h4 {
    color: #2c3d89;
    font-size: 14px;
    text-align: center;
  }

  .mobile-testimonials p {
    text-align: center;
    margin-bottom: 20px;
  }

  /*About Page Responsive Styling Begins Here*/

  .about-us-intro {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .about-us-intro h1 {
    font-size: 34px;
  }

  /**About Mission Vision Values**/

  .mission-vision-values {
    margin-left: 10px;
    margin-right: 10px;
  }

  /**About Regional Governing Bodies**/

  .region-card {
    width: 320px;
    height: 200px;
  }

  /**About Lead Gen Mini**/

  .lead-gen-mini-main {
    gap: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lead-gen-mini-main .lead-gen-mini-btn a {
    padding: 20px;
  }

  /*About Page Responsive Styling Ends Here*/

  /*Membership Page Responsive Styling Begins Here*/

  .membership-main .membership-details .member-info .info-card {
    width: 300px;
  }

  /*Membership Page Responsive Styling Begins Here*/

  /*Privacy Policy Page Responsive Styling Begins*/

  .privacy-notice {
    gap: 15px;
    margin: 20px;
  }

  .privacy-notice p {
    font-size: 14px;
    line-height: 1.2;
  }

  .privacy-notice h2 {
    font-size: 22px;
  }

  .privacy-notice h3 {
    font-size: 18px;
  }

  .privacy-notice ul {
    font-size: 13px;
  }

  /*Privacy Policy Page Responsive Styling Ends*/

  /*Footer*/

  .footer-subscribe {
    max-width: 420px;
  }

  .footer-lower {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-lower .copyright p {
    text-align: center;
  }
}

/*MAX w 391*/

@media (max-width: 391px) {
  /*Header*/

  .header {
    column-gap: 30px;
  }

  .logo img {
    max-width: 120px;
    max-height: 120px;
  }

  #menu_icon {
    font-size: 35px;
    margin-right: 20px;
  }

  #main_nav ul {
    padding: 5px;
  }

  #main_nav ul li {
    font-size: 14px;
  }

  #main_nav ul li:hover > ul li {
    font-size: 11px;
    margin-bottom: 15px;
  }

  .img-container img {
    width: 300px;
    height: 300px;
  }

  .intro-buttons {
    gap: 5px;
    font-size: 11px;
  }

  #contact_btn,
  #more_about {
    padding: 15px 10px;
  }

  /*Achievements Overview*/

  .achievements-grid .achievement-description .achievement-desc-text p {
    font-size: 11px;
  }

  /*Achievements Overview*/

  /*Events Overview*/

  .other-event-box .other-event-description .event-desc-texts p {
    font-size: 11px;
  }

  /*Partner Recognition*/

  .partner-recognition-intro p {
    margin-bottom: 0;
  }

  .partner-logos img {
    width: 200px;
    height: 200px;
  }

  /*Client Testimonials*/

  .client-testimonials-section .testi-wrapper {
    padding: 5px;
  }

  .client-testimonials-intro h2 {
    position: relative;
    top: 10px;
  }

  .mobile-testimonials h3 {
    font-size: 20px;
  }

  .mobile-testimonials h4 {
    font-size: 11px;
  }

  /**About Regional Governing Bodies**/

  .region-card {
    width: 300px;
  }

  /**About Lead Gen Mini**/

  .lead-gen-mini-main {
    padding: 20px;
  }

  .lead-gen-mini-main .lead-gen-mini-btn a {
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
  }

  /*Events Page Responsive Styling Begins Here*/

  .events-main .event-content .upcoming-events-list,
  .events-main .event-content .past-events-list,
  .news-main .news-content {
    gap: 20px;
  }

  .events-main .event-content .event-card {
    gap: 0px;
    width: 320px;
  }

  .events-main .event-content .event-card .event-image img {
    width: 320px;
  }

  /*Events Page Responsive Styling Ends Here*/

  /*Footer*/

  .footer-socials {
    gap: 20px;
  }

  .footer-socials a i {
    font-size: 30px;
  }
}

/*Animations*/

@keyframes imgSpinner {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.5;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes entranceAnime {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.4;
  }

  40% {
    opacity: 0.6;
  }

  60% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}
