.stats-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, hsl(239, 90%, 15%) 0%, #181a33 100%);
  padding: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
}

.stat-card {
  background-color: white;
  color: #222;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.stat-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.stat-card p {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.stat-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.total {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.stats-container a {
  background-color: #0d8b44;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.4s ease;
}

.stats-container a:hover {
  background-color: #ea1d26;
}

.stats-container h2 {
  color: #eee;
}
