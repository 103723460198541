/* General Page Layout */
.resources {
  width: 100%;
  background-color: whitesmoke;
}
.resources-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.resources-page h1 {
  font-size: 2.5rem;
  text-align: center;
}

.resources-page 
.section {
  margin-bottom: 40px;
}

.resources-page h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.resources-page h5 {
  font-size: 15px;
  font-style: normal;
}

.resources-page p {
  font-size: 1.1rem;
  color: #040404;
  margin-bottom: 20px;
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.resource-item {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px rgb(245, 233, 233);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resource-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.resource-icon {
  width: 60px;
  margin: 0 auto 15px;
}

.doc-name {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center !important;
}

/* Download button styles */
.download-link {
  background-color: #2c3d89;
  color: white;
  padding: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.4s ease, transform 0.3s ease;
}

.download-link:hover {
  background-color: #0d8b44;
  transform: scale(1.05);
  text-decoration: none;
}

.download-link .fa-download {
  margin-right: 8px;
}

.resource-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .resources-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  .resources-grid {
    grid-template-columns: 1fr;
  }
}
